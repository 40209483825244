'use client'

import { configurableModules } from 'src/modules'

import { getCheckoutConfig } from './checkoutConfig'
import { CheckoutConfigParams, ModuleTypes } from './checkoutConfigs.types'

export interface Checkout {
  modules: ModuleTypes
  config: CheckoutConfigParams
}

export function checkoutFactory(productSellGroupId: number): Checkout {
  const requestedConfig = getCheckoutConfig(productSellGroupId)

  const requestedModules = requestedConfig.modulesConfigs.reduce(
    (acc, moduleConfig) => {
      const module = configurableModules[moduleConfig.name]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const paramsModule = moduleConfig.params as any

      return {
        ...acc,
        [moduleConfig.name]: module(paramsModule),
      }
    },
    {} as ModuleTypes
  )

  return {
    modules: requestedModules,
    config: requestedConfig,
  }
}
