import { env } from 'src/configs/env'

/**
 * Generates a URL for an asset based on the provided path.
 *
 * @param path - Optional. The path to the asset. If not provided, returns the base assets URL.
 * @returns The URL for the asset.
 */
export const getUrlForAsset = (path?: string) => {
  const url = new URL(path ?? '', env.ASSETS_URL)
  return url.toString()
}
