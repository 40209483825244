import { Offer } from 'src/features/plan/offer/typings/offerTypings'

export type Coupon = {
  id: number
  couponCode: string
  discountAmount: number
  purchaseTypeAllowed: PurchaseTypeAllowed
  destinationOffer: Omit<Offer, 'orderBump'>
}

export type CouponUserData = {
  email: string | null
  document: string | null
}

export type CouponError = {
  message: string
}

export enum PurchaseTypeAllowed {
  ALL = 'all',
  FIRST = 'first',
  RECURRENT = 'recurrent',
}
