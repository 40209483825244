import { useAtom } from 'jotai'

import { cupomDigitalCheckoutAtoms } from '../atoms/cupomDigitalCheckoutAtoms'

export function useCupomDigitalCheckoutStore() {
  const { cupomDigitalCheckoutAtom, focusedDigitalCheckoutAtom } =
    cupomDigitalCheckoutAtoms

  const [selectedCupomDigitalCheckout, setSelectedCupomDigitalCheckout] =
    useAtom(cupomDigitalCheckoutAtom)

  const [focusedDigitalCheckout, setFocusedDigitalCheckout] = useAtom(
    focusedDigitalCheckoutAtom
  )

  return {
    selectedCupomDigitalCheckout,
    setSelectedCupomDigitalCheckout,
    focusedDigitalCheckout,
    setFocusedDigitalCheckout,
  }
}
