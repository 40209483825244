import { env } from 'src/configs/env'
import { Offer } from 'src/features/plan/offer/typings/offerTypings'
import { apiProductPayments } from 'src/infra/api'

import {
  ValidateCouponPayload,
  ValidateCouponResponse,
} from '../../../types/gateway.types'

async function validateCoupon(
  payload: ValidateCouponPayload
): Promise<ValidateCouponResponse> {
  const data = (
    await apiProductPayments
      .url(`${env.PATH_PAYMENTS_COUPON_VALIDATE}`)
      .post(payload).json
  )<ValidateCouponResponse>()

  return data
}

async function getOfferDestinationCoupon(offerId: number): Promise<Offer> {
  const data = (
    await apiProductPayments.url(`${env.PATH_OFFER}/${offerId}`).get().json
  )<Offer>()

  return data
}

export const couponGateway = {
  validateCoupon,
  getOfferDestinationCoupon,
}
