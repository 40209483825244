import styles from './styles.module.css'

export const configSteps = [
  {
    key: 1,
    content: (
      <p>
        <b>Não precisa preencher</b> os dados do seu cartão.
      </p>
    ),
  },
  {
    key: 2,
    content: (
      <p>
        <b>É seguro</b> e protegido pelo app do Nubank.
      </p>
    ),
  },
  {
    key: 3,
    content: (
      <p>
        Possibilidade de receber <b>limite adicional</b> nas cobranças no
        crédito.
      </p>
    ),
  },
]

export function DisplayStepsAuthorization() {
  return (
    <div className={styles.container}>
      <p>
        Para <b>essa</b> e <b>próximas</b> assinaturas em{`\n`}Queima Diária
      </p>
      <ul className={styles.list}>
        {configSteps.map(({ key, content }) => (
          <li key={key}>{content}</li>
        ))}
      </ul>
      <span>
        * Para usar esta forma de pagamento, você precisa ser cliente do Nubank.
      </span>
    </div>
  )
}
