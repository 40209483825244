import { MouseEvent } from 'react'

import PhoneSvg from '../../assets/phone.svg'
import { DisplayStepsAuthorization } from '../DisplayStepsAuthorization'
import { HeadNupay } from '../HeadNupay'
import styles from './styles.module.css'

export type PreAuthorizationProps = {
  onRequestAuthorization: () => Promise<void>
  isLoadingAuthorization: boolean
}

export const PreAuthorization = ({
  onRequestAuthorization,
  isLoadingAuthorization,
}: PreAuthorizationProps) => {
  const handleClickAuthorization = (
    mouseEvent: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    mouseEvent.preventDefault()
    mouseEvent.stopPropagation()
    onRequestAuthorization()
  }
  return (
    <div className={styles.container}>
      <HeadNupay title='Autorizar pagamentos automáticos com Nubank' />

      <DisplayStepsAuthorization />
      <button
        type='button'
        className={styles.buttonAuthorization}
        onClick={handleClickAuthorization}
        disabled={isLoadingAuthorization}
      >
        {isLoadingAuthorization ? (
          <div className={styles.loader} />
        ) : (
          <>
            <PhoneSvg />
            Autorizar no app do Nubank
          </>
        )}
      </button>
    </div>
  )
}
