import * as Select from '@radix-ui/react-select'
import { Control, Controller } from 'react-hook-form'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import CheckIcon from 'src/features/product/components/CheckIcon'
import { formatCurrency } from 'src/utils/currency'

import { InstallmentNupay, PaymentChosenFormData } from '../../types'
import styles from './styles.module.css'

export type SelectInstallmentProps = {
  installments: Array<InstallmentNupay>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<PaymentChosenFormData, any>
}

export function SelectInstallment({
  installments,
  control,
}: SelectInstallmentProps) {
  return (
    <div className={styles.container}>
      <label htmlFor='installmentsNupay'>Opções de parcelamento</label>
      <Controller
        name='installment'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value, ...restField } }) => (
          <Select.Root
            {...restField}
            onValueChange={onChange}
            value={`${value}`}
          >
            <Select.Trigger
              className={styles.selectTrigger}
              aria-label='installments'
            >
              <Select.Value />
              <Select.Icon className={styles.selectIcon}>
                <FaChevronDown color='#989898' />
              </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
              <Select.Content className={styles.selectContent}>
                <Select.ScrollUpButton className={styles.selectScrollButton}>
                  <FaChevronUp />
                </Select.ScrollUpButton>
                <Select.Viewport
                  data-testid='installmentsNupay'
                  className={styles.selectViewport}
                  id='installmentsNupay'
                >
                  {installments.map(({ installment, price }) => (
                    <Select.Item
                      key={`${installment}`}
                      value={`${installment}`}
                      className={styles.selectItem}
                    >
                      <Select.ItemText>{`${installment}x de ${formatCurrency(
                        price
                      )}`}</Select.ItemText>
                      <Select.ItemIndicator
                        className={styles.selectItemIndicator}
                      >
                        <CheckIcon />
                      </Select.ItemIndicator>
                    </Select.Item>
                  ))}
                </Select.Viewport>
                <Select.ScrollDownButton className={styles.selectScrollButton}>
                  <FaChevronDown />
                </Select.ScrollDownButton>
              </Select.Content>
            </Select.Portal>
          </Select.Root>
        )}
      />
    </div>
  )
}
