import { useMemo } from 'react'

import { NupayComponentProps } from '../NupayMethod.types'
import { PaymentChosen } from './PaymentChosen'
import { PendingAuthorization } from './PendingAuthorization'
import { PreAuthorization } from './PreAuthorization'
import styles from './styles.module.css'
import { useNupayComponent } from './useNupayComponent'

export const NupayComponent = (props: NupayComponentProps) => {
  const {
    isPendingAuthorization,
    handleRequestAuthorization,
    handleAuthorizationPolling,
    paymentOptions,
    handleChangePaymentChosen,
    isSendedAuthorization,
    isFallback,
  } = useNupayComponent(props)
  const content = useMemo(() => {
    return isPendingAuthorization ? (
      <PendingAuthorization
        handleAuthorizationPolling={handleAuthorizationPolling}
        onError={props.onError}
      />
    ) : !!paymentOptions ? (
      <PaymentChosen
        paymentOptions={paymentOptions}
        onChange={handleChangePaymentChosen}
        fallbackInstallment={props.offer.fallbackInstallment}
        isFallback={isFallback}
      />
    ) : (
      <PreAuthorization
        onRequestAuthorization={handleRequestAuthorization}
        isLoadingAuthorization={isSendedAuthorization}
      />
    )
  }, [
    isPendingAuthorization,
    handleAuthorizationPolling,
    props.onError,
    paymentOptions,
    handleChangePaymentChosen,
    handleRequestAuthorization,
    isSendedAuthorization,
  ])

  return <div className={styles.container}>{content}</div>
}
