import { BilletMethodConfig } from './BilletMethod.types'
import { pagarmeBillet } from './providers/pagarme'

export const billetMethod = ({ provider }: BilletMethodConfig) => {
  const billetModulesByProvider = {
    pagarme: pagarmeBillet,
  }

  return {
    component: billetModulesByProvider[provider],
  }
}
