import { AvailableTemplates } from 'src/templates/templates.types'

import { CheckoutConfig, CheckoutConfigParams } from './checkoutConfigs.types'

const checkoutConfig: CheckoutConfig = {
  'digital-default': {
    modulesConfigs: [
      {
        name: 'countdownModule',
        params: {
          template: 'DefaultTemplateComponent',
        },
      },
      {
        name: 'couponModule',
        params: {
          provider: 'digital',
        },
      },
      {
        name: 'paymentModule',
        params: {
          methods: {
            billetConfig: {
              provider: 'pagarme',
            },
            creditCardConfig: {
              provider: 'adyen',
            },
            nupayConfig: true,
            pixConfig: {
              provider: 'adyen',
            },
          },
        },
      },
    ],
    template: AvailableTemplates.SingleStep,
    renderProps: {
      specialOfferConfig: {
        default: {
          title: 'Checkout Seguro',
        },
        special: {
          title: 'Pagamento Seguro',
        },
      },
    },
  },
  'physical-default': {
    modulesConfigs: [],
    template: AvailableTemplates.SingleStep,
    renderProps: {},
  },
}

export const getCheckoutConfig = (
  productSellGroupId: number
): CheckoutConfigParams => {
  const selectedConfig = checkoutConfig[productSellGroupId]
  if (selectedConfig) return selectedConfig

  if (productSellGroupId < 9999) {
    return checkoutConfig['digital-default']
  }

  return checkoutConfig['physical-default']
}
