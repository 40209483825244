import { FormEvent, useEffect, useImperativeHandle, useState } from 'react'

import {
  Coupon,
  CouponError,
  CouponUserData,
  PurchaseTypeAllowed,
} from 'src/modules/coupon/types/coupon.types'
import {
  CouponComponentProps,
  CouponFns,
} from 'src/modules/coupon/types/couponModule.types'
import { ValidateCouponPayload } from 'src/modules/coupon/types/gateway.types'

import { getCouponError } from '../../../utils/coupon'
import { couponGateway } from '../gateway'

type DigitalCouponProps = CouponComponentProps

export function useDigitalCoupon({
  onChangeCoupon,
  productSellGroupId,
  coupon,
  offerId,
  couponRef,
}: DigitalCouponProps) {
  const [couponData, setCouponData] = useState<Coupon | undefined>(coupon)
  const [couponUserData, setCouponUserData] = useState<CouponUserData>({
    email: null,
    document: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [couponError, setCouponError] = useState<CouponError | null>(null)
  const [couponInputValue, setCouponInputValue] = useState('')

  useEffect(() => {
    if (
      couponData &&
      couponData.purchaseTypeAllowed !== PurchaseTypeAllowed.ALL
    ) {
      validateCoupon(couponData.couponCode)
    }
  }, [couponUserData])

  useEffect(() => {
    onChangeCoupon(couponData)
  }, [couponData])

  function clearCoupon() {
    setCouponData(undefined)
    clearCouponError()
  }

  function clearCouponError() {
    setCouponError(null)
  }

  function handleCouponError(error: CouponError) {
    setCouponError(error)
    setCouponData(undefined)
  }

  const updateCouponUserData: CouponFns['updateCouponUserData'] =
    couponUserData => {
      setCouponUserData(couponUserData)
    }

  async function validateCoupon(code: string) {
    try {
      setIsLoading(true)
      const payload: ValidateCouponPayload = {
        code,
        productSellGroupId,
        email: couponUserData.email,
        document: couponUserData.document,
        offerId,
      }

      const couponResponse = await couponGateway.validateCoupon(payload)
      if (couponResponse.success) {
        const destinationOffer = await couponGateway.getOfferDestinationCoupon(
          couponResponse.destinationOfferId
        )
        if (destinationOffer) {
          setCouponData({
            couponCode: code,
            id: couponResponse.id,
            discountAmount: couponResponse.discountAmount,
            purchaseTypeAllowed: couponResponse.purchaseTypeAllowed,
            destinationOffer,
          })
          return
        }
      }
      const errorMessage = getCouponError(
        code,
        couponResponse.errors[0].errorCode
      )
      handleCouponError(errorMessage)
    } catch (err: unknown) {
      console.error(err)
      handleCouponError({
        message: 'Não foi possível buscar o cupom',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCouponInput = (code: string) => {
    setCouponInputValue(code.toUpperCase())
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (!couponInputValue) return
    validateCoupon(couponInputValue)
    setCouponInputValue('')
  }

  useImperativeHandle(couponRef, () => ({
    updateCouponUserData,
  }))

  return {
    handleSubmit,
    handleCouponInput,
    couponData,
    couponError,
    isLoading,
    clearCoupon,
    clearCouponError,
    couponInputValue,
    onSubmit,
    handleKeyDown,
  }
}
