import { css } from '@emotion/react'
import styled from '@emotion/styled'

type ButtonSizes = 'md' | 'lg'
export interface ButtonProps {
  variation?: 'outline' | 'solid'
  size?: ButtonSizes
  type?: 'submit' | 'button'
  onClick: () => void
  children: string | JSX.Element
}

export const Button = ({
  children,
  variation = 'solid',
  size = 'md',
  onClick,
  type = 'button',
}: ButtonProps) => {
  return variation === 'outline' ? (
    <OutlineButton size={size} type={type} onClick={() => onClick()}>
      {children}
    </OutlineButton>
  ) : (
    <SolidButton size='lg' type={type} onClick={() => onClick()}>
      {children}
    </SolidButton>
  )
}

const OutlineButton = styled.button<{ size: ButtonSizes }>(({ size }) =>
  css({
    border: '1.5px solid #ff4f00',
    color: '#ff4f00',
    borderRadius: '6px',
    padding: size === 'md' ? '8px 24px' : '14px 28px',
    cursor: 'pointer',
  })
)

const SolidButton = styled.button<{ size: ButtonSizes }>(({ size }) =>
  css({
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    padding: size === 'md' ? '8px 24px' : '14px 28px',
    backgroundColor: '#ff4f00',
    color: 'white',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.8,
    },
  })
)
