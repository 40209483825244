import { PaymentOptions } from './types'

export function defineInstallmentDefaultValue(
  creditPayment: PaymentOptions['creditPayment']
) {
  if (creditPayment.isAvailable && creditPayment.installments.length > 0) {
    return creditPayment.installments[creditPayment.installments.length - 1]
      .installment
  }

  return 1
}

export function defineMethodDefaultValue(
  creditPayment: PaymentOptions['creditPayment'],
  debitPayment: PaymentOptions['debitPayment']
) {
  if (creditPayment.isAvailable) {
    return 'credit'
  }

  if (debitPayment.isAvailable) {
    return 'debit'
  }
}

export function defineCreditTagDescription(
  creditPayment: PaymentOptions['creditPayment']
) {
  if (creditPayment.isAvailable && creditPayment.installments.length > 1) {
    const conjunctive = creditPayment.installments.length > 1 ? ' até ' : ' '
    return `Em${conjunctive}${
      creditPayment.installments[creditPayment.installments.length - 1]
        .installment
    }x`
  }
}
