import { Flex, Spinner } from '@queimadiaria/ui'
import { useAtomValue } from 'jotai'
import { FC } from 'react'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'
import { useIsCrossSellCheckout } from 'src/features/crossSellCheckout/hooks/useIsCrossSellCheckout'

export const CreditCardLoadingState: FC = () => {
  const isCrossSellCheckout = useIsCrossSellCheckout()
  const isNewCheckout = useAtomValue(checkoutAtoms.isNewCheckout)

  if (!isNewCheckout) {
    return (
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F4F4F4',
          stroke: '#DBDBDB ',
          border: '1px solid #E8E8E8',
          borderRadius: '8px',
          fontSize: 'xlarge',
          height: 380,
          marginLeft: isCrossSellCheckout ? null : [0, -24],
        }}
      >
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FAFAFA',
        fontSize: 'xlarge',
        height: 380,
      }}
    >
      <Spinner />
    </Flex>
  )
}
