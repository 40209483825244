import { Header } from 'src/features/layout/components/Header'
import { Button } from 'src/ui/components/atoms/Button'

import ErrorIcon from './assets/error-red.svg'
import RefreshIcon from './assets/refresh.svg'
import { useErrorFullScreenModal } from './hooks/useErrorFullScreenModal'
import styles from './styles.module.css'

export const ErrorFullScreenModal = () => {
  const {
    isErrordigitalCheckoutOpen,
    errorDigitalCheckout,
    hideErrorFullScreenModal,
  } = useErrorFullScreenModal()
  return isErrordigitalCheckoutOpen ? (
    <div data-testid='error-modal' className={styles.fullScreenContainer}>
      <Header />
      <div className={styles.content}>
        <ErrorIcon className={styles.icon} />
        <h2>{errorDigitalCheckout?.title}</h2>
        <p>{errorDigitalCheckout?.text}</p>
        <Button onClick={hideErrorFullScreenModal}>
          <>
            Refazer compra&nbsp;
            <RefreshIcon />
          </>
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}
