import { CreditCardMethodConfig } from './CreditCardMethod.types'
import { adyenCreditCard } from './providers/adyen'
import { legacyCreditCard } from './providers/legacy'

export const creditCardMethod = ({ provider }: CreditCardMethodConfig) => {
  const creditCardProvidersByProvider = {
    adyen: adyenCreditCard,
    legacy: legacyCreditCard,
  }

  return {
    component: creditCardProvidersByProvider[provider],
  }
}
