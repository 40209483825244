import { HTMLAttributes } from 'react'

import styles from './styles.module.css'

export type BadgeProps = {
  text: string
  color: string
  containerStyles?: HTMLAttributes<HTMLDivElement>['style']
}

export function Badge({ text, color, containerStyles }: BadgeProps) {
  return (
    <div
      className={styles.container}
      style={{ ...(containerStyles || {}), backgroundColor: color }}
    >
      {text}
    </div>
  )
}
