import { SerializedApiError } from 'src/infra/typings/globalTypings'

export function createUnknownSerializedApiError() {
  const serializedError: SerializedApiError = {
    isSerializedApiError: true,
    statusCode: 500,
    code: 'unknown_error',
  }

  return serializedError
}
