import { env } from 'src/configs/env'
import { apiPayments } from 'src/infra/api'

import {
  CheckAuthorizationStatusPayload,
  CheckAuthorizationStatusResponse,
  PaymentConditionsPayload,
  PaymentConditionsResponse,
  RequestAuthorizationPayload,
  RequestAuthorizationResponse,
} from './types/gateway.types'

async function requestAuthorization(payload: RequestAuthorizationPayload) {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_NU_PAY}/authorization`)
    .post({
      ...payload,
      flowType: 'ciba',
    })
    .json<RequestAuthorizationResponse>()

  return data
}

async function checkAuthorizationStatus(
  payload: CheckAuthorizationStatusPayload
): Promise<CheckAuthorizationStatusResponse> {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_NU_PAY}/status`)
    .post(payload)
    .json<CheckAuthorizationStatusResponse>()
  return data
}

async function getPaymentConditions(payload: PaymentConditionsPayload) {
  const data = await apiPayments
    .url(`${env.PATH_PAYMENTS_NU_PAY}/payment-conditions`)
    .post(payload)
    .json<PaymentConditionsResponse>()
  return data
}

export const nupayGateway = {
  requestAuthorization,
  checkAuthorizationStatus,
  getPaymentConditions,
}
