import { CouponError } from '../types/coupon.types'

export const getCouponError = (
  couponCode: string,
  errorCode: string
): CouponError => {
  const COUPON_ERRORS: { [errorCode: string]: string } = {
    '001': 'não encontrado',
    '002': 'expirado',
    '003': 'atingiu o limite de uso',
    '006': 'atingiu o limite de uso',
    '004': 'não é válido',
    '005': 'não se aplica a esse produto',
    '422': 'Digite o texto do Cupom',
  }
  return {
    message: COUPON_ERRORS[errorCode]
      ? `Cupom ${couponCode} ${COUPON_ERRORS[errorCode]}`
      : `Cupom ${couponCode} não é válido`,
  }
}
