import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { HeadNupay } from '../HeadNupay'
import { MethodPaymentRadioGroup } from './Form/MethodPaymentRadioGroup'
import { SelectInstallment } from './Form/SelectInstallment'
import {
  defineCreditTagDescription,
  defineInstallmentDefaultValue,
  defineMethodDefaultValue,
} from './helper'
import styles from './styles.module.css'
import { PaymentChosenFormData, PaymentChosenProps } from './types'

export function PaymentChosen({
  paymentOptions: { creditPayment, debitPayment },
  onChange,
  fallbackInstallment,
  isFallback,
}: PaymentChosenProps) {
  const { control, watch, setValue } = useForm<PaymentChosenFormData>({
    defaultValues: {
      installment: defineInstallmentDefaultValue(creditPayment),
      method: defineMethodDefaultValue(creditPayment, debitPayment),
    },
  })

  const creditTagDescription = !isFallback
    ? defineCreditTagDescription(creditPayment)
    : undefined

  const [installment, method] = watch(['installment', 'method'])

  useEffect(() => {
    if (isFallback) {
      setValue('installment', fallbackInstallment.installment)
      return
    }

    if (method === 'debit') {
      setValue('installment', 1)
    }
  }, [method, fallbackInstallment, isFallback])

  useEffect(() => {
    onChange({
      method,
      installment,
    })
  }, [installment, method])

  const isShowSelectInstallment =
    creditPayment.isAvailable &&
    creditPayment.installments.length > 1 &&
    method === 'credit' &&
    !isFallback

  return (
    <div className={styles.container}>
      <HeadNupay title='Pagamentos em um clique com Nubank' />
      <p>Como você quer pagar com Nubank?</p>
      <div>
        <MethodPaymentRadioGroup
          control={control}
          methodWatch={method}
          creditTagDescription={creditTagDescription}
          disabledDebit={!debitPayment.isAvailable}
          disabledCredit={!creditPayment.isAvailable}
        />

        {isShowSelectInstallment && (
          <SelectInstallment
            control={control}
            installments={creditPayment.installments}
          />
        )}
      </div>
    </div>
  )
}
