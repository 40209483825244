import { useMemo } from 'react'

import { useSizeScreen } from 'src/ui/hooks/useSizeScreen'

import NbPhone from './../../assets/nb_phone.svg'
import QdNpDesktop from './../../assets/qd_np_desktop.svg'
import styles from './styles.module.css'

export const PendingIcon = () => {
  const { isBigger } = useSizeScreen()
  const isMobile = !isBigger(768)

  const Icon = useMemo(() => {
    return isMobile ? <NbPhone /> : <QdNpDesktop />
  }, [isMobile])

  return <div className={styles.iconContainer}>{Icon}</div>
}
