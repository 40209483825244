import { nanoid } from 'nanoid'

import { env } from 'src/configs/env'

export function getGoogleAnalyticsClientId() {
  return new Promise<string>(resolve => {
    try {
      if (!env.GOOGLE_ANALYTICS_ID) return resolve(nanoid())

      gtag('get', env.GOOGLE_ANALYTICS_ID, 'client_id', clientId => {
        resolve(clientId)
      })

      sendIdWhenGtagIsInInfiniteLoop(resolve)
    } catch (error) {
      resolve(nanoid())
    }
  })
}

function sendIdWhenGtagIsInInfiniteLoop(
  resolve: (value: string | PromiseLike<string>) => void
) {
  setTimeout(() => resolve(nanoid()), 3000)
}
