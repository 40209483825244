import { billetMethod } from './methods/billet'
import { creditCardMethod } from './methods/creditCard'
import { NupayMethod } from './methods/nupay'
import { pixMethod } from './methods/pix'
import { PaymentModule } from './paymentModule.types'

export const paymentModule: PaymentModule = ({
  methods: { billetConfig, creditCardConfig, pixConfig, nupayConfig },
}) => {
  const creditCardData = !!creditCardConfig
    ? { creditCard: creditCardMethod(creditCardConfig) }
    : {}

  const billetData = !!billetConfig
    ? { billet: billetMethod(billetConfig) }
    : {}

  const pixData = !!pixConfig ? { pix: pixMethod(pixConfig) } : {}

  const nupayData = !!nupayConfig ? { nupay: NupayMethod() } : {}

  return {
    methods: {
      ...creditCardData,
      ...billetData,
      ...pixData,
      ...nupayData,
    },
  }
}
