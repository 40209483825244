import { useCupomDigitalCheckoutStore } from 'src/features/CupomDigitalCheckout/hooks/useCupomDigitalCheckoutStore'
import { useOrderBumpCheckoutStore } from 'src/features/orderBump/hooks/useOrderBumpCheckoutStore'

import { Offer } from '../typings/offerTypings'

export function useChosenOffer(offer?: Offer) {
  const { isSelectedOrderBump } = useOrderBumpCheckoutStore()
  const { selectedCupomDigitalCheckout } = useCupomDigitalCheckoutStore()

  if (!offer) return null

  if (!!offer.isDisplayCoupon) {
    if (!!selectedCupomDigitalCheckout)
      return selectedCupomDigitalCheckout.destinationOffer
  } else {
    if (!!offer.orderBump && !!offer.orderBump.isActive && isSelectedOrderBump)
      return offer.orderBump.destinationOffer
  }
  return offer
}
