import styled from '@emotion/styled'
import { Box, Text } from '@queimadiaria/ui'
import { isAfter, isBefore } from 'date-fns'
import { useRef } from 'react'

import { useCountdown } from 'src/hooks/useCountdown/useCountdown'
import { useSizeScreen } from 'src/ui/hooks/useSizeScreen'
import { getUrlForAsset } from 'src/utils/assetsUtils'

import { ClassicTimer } from '../../components/ClassicTimer'
import { CountdownTemplate } from '../../countdownModule.types'
import { DefaultTemplateProps } from './defaultTemplate.types'

export type DefaultTemplateBackgroundProp = [
  startColor: string,
  endColor: string
]

type DefaultTemplate = CountdownTemplate<
  React.ReactElement,
  DefaultTemplateProps
>

export const DefaultTemplateComponent: DefaultTemplate = ({
  startAt,
  endsAt,
  leftImage,
  offerImage,
  offerImageMobile,
  bgColor,
  leftImageMobile,
}: DefaultTemplateProps) => {
  const { isBigger } = useSizeScreen()
  const isMobile = !isBigger(720)

  const hasStarted = isBefore(startAt, new Date())
  const hasExpired = isAfter(new Date(), endsAt)

  const countdown = useCountdown(endsAt, hasStarted)

  const ref = useRef<HTMLDivElement>(null)

  if (!hasStarted || hasExpired) return <></>

  return (
    <Root ref={ref}>
      <Container
        data-testid='countdown'
        style={{ backgroundColor: bgColor ?? '#000000' }}
      >
        <ContainerImgAndTime>
          {
            <img
              src={getUrlForAsset(isMobile ? leftImageMobile : leftImage)}
              alt='Queima Fridary'
            />
          }
          <TimeContainer>
            <UniqueChance>CHANCE ÚNICA:</UniqueChance>
            <ClassicTimer fgColor='#000' bgColor='#FFF' countdown={countdown} />
          </TimeContainer>
        </ContainerImgAndTime>
        <ContainerOffer>
          {isMobile && offerImageMobile ? (
            <img
              src={getUrlForAsset(offerImageMobile)}
              style={{ maxWidth: '80px' }}
              alt='Oferta'
            />
          ) : (
            <img src={getUrlForAsset(offerImage)} alt='Oferta' />
          )}
        </ContainerOffer>
      </Container>
    </Root>
  )
}

const Root = styled(Box)`
  overflow: 'scroll';
  position: relative;
`

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 16px;

  @media only screen and (max-width: 720px) {
    padding-block: 12px;
  }
`

const ContainerImgAndTime = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-inline: 24px;
  img {
    max-width: 156px;
    margin-inline-end: 24px;
  }
  @media only screen and (max-width: 720px) {
    flex-direction: column;

    img {
      width: 91px;
      margin-inline-end: 0px;
    }
  }
`

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const UniqueChance = styled(Text)`
  font-family: 'Graphik';
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin-block-end: 4px;
`

const ContainerOffer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    max-width: 240px;
  }

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    > img {
      width: 160px;
    }
    img {
      margin-inline-end: 0px;
    }
    div:first-of-type {
      margin-block-start: 14px;
    }
  }
`
