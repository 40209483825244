import styled from '@emotion/styled'
import { FiX } from 'react-icons/fi'

import { Button } from 'src/ui/components/atoms/Button'
import { TextInput } from 'src/ui/components/atoms/TextInput'

import { CouponComponentProps } from '../../types/couponModule.types'
import { CouponIcon } from './components/CouponIcon'
import { CouponLoader } from './components/CouponLoader'
import { useDigitalCoupon } from './hooks/useDigitalCoupon'

export const DigitalCouponComponent = ({
  productSellGroupId,
  coupon,
  couponRef,
  onChangeCoupon,
  offerId,
}: CouponComponentProps) => {
  const {
    isLoading,
    couponError,
    clearCouponError,
    couponData,
    clearCoupon,
    couponInputValue,
    handleCouponInput,
    handleSubmit,
    onSubmit,
    handleKeyDown,
  } = useDigitalCoupon({
    onChangeCoupon,
    productSellGroupId,
    coupon,
    offerId,
    couponRef,
  })

  return (
    <>
      {isLoading ? (
        <LoaderContainer>
          <CouponLoader color='#ff4f00' />
        </LoaderContainer>
      ) : !couponData ? (
        <CouponForm onSubmit={onSubmit}>
          <label htmlFor='code'>Tem um cupom?</label>
          <CouponInputContainer>
            <TextInput
              name='code'
              placeholder='Código do cupom'
              value={couponInputValue}
              onValueChange={code => handleCouponInput(code)}
              icon={<CouponIcon />}
              hasErrors={!!couponError}
              onBlur={clearCouponError}
              onKeyDown={handleKeyDown}
              onSubmit={({ preventDefault }) => preventDefault()}
            />
            <Button type='button' variation='outline' onClick={handleSubmit}>
              Adicionar
            </Button>
          </CouponInputContainer>
          {!!couponError && <CouponError>{couponError.message}</CouponError>}
        </CouponForm>
      ) : (
        <CouponFilledContainer>
          <CouponIcon borderColor='#ff4f00' />
          <span>{couponData.couponCode}</span>
          <button onClick={clearCoupon}>
            <FiX color='#252525' size={12} />
          </button>
        </CouponFilledContainer>
      )}
    </>
  )
}

const CouponForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;

  & label {
    font-size: 14px;
    line-height: 17.5px;
    color: #525252;
    font-weight: bold;
  }
`

const CouponFilledContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff6ec;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 4px;

  > span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    flex: 1;
  }

  > button {
    padding: 8px;
    border: none;
    margin: 0;
    transition: transform 0.7s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
`

const CouponInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`

const CouponError = styled.div`
  color: #bf1620;
  font-size: 14px;
  line-height: 17.5px;
`
