/**
 * Extracts only numerical digits from a string.
 *
 * @param rawValue - The string containing numerical and non-numerical characters.
 * @returns A new string containing only the numerical digits from the input.
 */
export function getOnlyNumbers(rawValue: string) {
  return rawValue.replace(/\D/g, '')
}

/**
 * Validates a CPF (Cadastro de Pessoas Físicas) number.
 *
 * @param inputCpf - The CPF number to validate.
 * @returns A boolean indicating whether the CPF number is valid.
 */
export function validatorCPF(inputCpf: string) {
  const strCPF = getOnlyNumbers(inputCpf)
  let sum = 0
  let rest

  if (strCPF === '00000000000') return false

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }

  rest = (sum * 10) % 11

  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(10, 11))) return false

  return true
}

/**
 * Extracts the DDD (area code) and phone number from a Brazilian phone number.
 *
 * @param inputPhone - The phone number to extract details from.
 * @returns An object containing the DDD and phone number.
 */
export function getPhoneDetails(inputPhone: string): {
  ddd: string
  phone: string
} {
  // Primeiro, removemos os caracteres não numéricos com uma expressão regular.
  const numericPhone = inputPhone.replace(/[^\d]/g, '')

  // Depois, separamos o DDD e o número do telefone.
  const ddd = numericPhone.trim().substring(0, 2)
  const phone = numericPhone.trim().substring(2)

  // Retornamos o objeto formatado.
  return {
    ddd,
    phone,
  }
}

/**
 * Formats the number of business days into a human-readable string.
 *
 * @param numberDay - The number of business days to display.
 * @returns A formatted string indicating the number of business days.
 * @throws Error if the input number of days is invalid (NaN or negative).
 */
export function displayDayUtil(numberDay: number) {
  if (isNaN(numberDay) || numberDay < 0)
    throw new Error('O número de dias está invalido para função displayDayUtil')

  return `${numberDay} ${numberDay === 1 ? 'dia útil' : 'dias úteis'}`
}

/**
 * Removes HTML break line tags from a string.
 *
 * @param input - The input string containing break line tags.
 * @returns A string with break line tags removed.
 */
export function clearBreakLine(input: string): string {
  return input.replace(/<br\s*\/?>/g, '')
}

/**
 * Applies line breaks to a string by replacing HTML break line tags with newline characters.
 *
 * @param input - The input string containing break line tags.
 * @returns A string with break line tags replaced by newline characters.
 */
export function applyBreakLine(input: string): string {
  return input.replace(/<br\s*\/?>/g, '\n')
}

/**
 * Applies line breaks to a string by replacing HTML break line tags with newline characters.
 *
 * @param email - The email string containing email to validate.
 * @returns A boolean indicating whether the email is valid.
 */
export function validatorEmail(email: string): boolean {
  // Define uma expressão regular mais robusta para validar o email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  // Verifica se a parte antes do @ e o domínio estão corretos
  if (!emailRegex.test(email)) {
    return false
  }

  // Divide o email para verificar partes adicionais
  const [, domain] = email.split('@')

  // Verifica se o domínio contém um ponto seguido por algo (não termina em um ponto)
  const domainParts = domain.split('.')
  if (domainParts.some(part => part.length === 0)) {
    return false
  }

  return true
}
