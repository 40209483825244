import { DigitalCouponComponent } from './providers/digital'
import { CouponModule } from './types/couponModule.types'

export const couponModule: CouponModule = ({ provider }) => {
  const couponComponentsByProviders = {
    digital: DigitalCouponComponent,
    physic: DigitalCouponComponent,
  }
  const CouponComponent = couponComponentsByProviders[provider]
  return {
    component: CouponComponent,
  }
}
