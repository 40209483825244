import { useEffect, useState } from 'react'

import { useAvailableCheckout } from 'src/features/plan/checkout/hooks/useAvailableCheckout'
import { useDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/useDigitalCheckoutContext'

import { useChosenOffer } from './useChosenOffer'

export function useOffer() {
  const { data, ...rest } = useAvailableCheckout()
  const [offerRecurrence, setOfferRecurrence] = useState('')
  const { getSubscriptionPeriodInfo } = useDigitalCheckoutContext()

  const chosenOffer = useChosenOffer(data?.offer)

  useEffect(() => {
    const offerRecurrenceUnit =
      !!chosenOffer && getSubscriptionPeriodInfo
        ? getSubscriptionPeriodInfo(chosenOffer.subscriptionPeriod)
            ?.recurrenceUnit || ''
        : ''
    setOfferRecurrence(offerRecurrenceUnit)
  }, [chosenOffer, getSubscriptionPeriodInfo])

  return {
    offer: data?.offer,
    ...rest,
    checkout: data?.checkout,
    chosenOffer,
    offerRecurrence,
  }
}
