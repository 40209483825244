import Head from 'next/head'

type THeadPageProductProps = {
  title: string
  siteName: string
  siteUrl: string
  imageUrl: string
  smallDescription: string
  largeDescription: string
  favicon?: string
  indexInGoogle?: boolean
  keywords?: string
}

export function HeadPage({
  title,
  siteName,
  siteUrl,
  imageUrl,
  smallDescription,
  largeDescription,
  favicon,
  indexInGoogle = true,
  keywords,
}: THeadPageProductProps) {
  return (
    <Head>
      <title>{title}</title>
      <link rel='icon' href={`/${favicon ?? 'favicon.ico'}`} />
      <meta name='description' content={smallDescription} />
      {keywords && <meta name='keywords' content={keywords} />}

      <meta name='twitter:title' content={title} />
      <meta name='twitter:image' content={imageUrl} />
      <meta name='twitter:description' content={largeDescription} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@queimadiaria' />
      <meta name='twitter:creator' content='@queimadiaria' />

      <meta property='og:title' content={title} />
      <meta property='og:image' content={imageUrl} />
      <meta property='og:description' content={largeDescription} />
      <meta property='og:site_name' content={siteName} />
      <meta property='og:url' content={siteUrl} />
      <meta property='og:image:alt' content={`Logo do ${siteName}`} />
      <meta property='og:locale' content='pt_BR' />
      <meta property='og:type' content='website' />

      <meta name='robots' content={indexInGoogle ? 'all' : 'none'} />
    </Head>
  )
}
