export default function CheckIcon() {
  return (
    <svg
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill='#00876A'
        d='M6.966 11.0813L13.3223 4.725C13.4723 4.575 13.6504 4.5 13.8566 4.5C14.0629 4.5 14.241 4.575 14.391 4.725C14.541 4.875 14.616 5.05313 14.616 5.25938C14.616 5.46563 14.541 5.64375 14.391 5.79375L7.491 12.6938C7.341 12.8438 7.166 12.9188 6.966 12.9188C6.766 12.9188 6.591 12.8438 6.441 12.6938L3.216 9.46875C3.066 9.31875 2.99412 9.14063 3.00038 8.93438C3.00663 8.72813 3.08475 8.55 3.23475 8.4C3.38475 8.25 3.56288 8.175 3.76913 8.175C3.97538 8.175 4.1535 8.25 4.3035 8.4L6.966 11.0813Z'
      />
    </svg>
  )
}
