import { createContext, useCallback, useState } from 'react'

import { Checkout, checkoutFactory } from 'src/configs/checkout'
import {
  CheckoutFeatureFlags,
  SubscriptionPeriodConfig,
} from 'src/configs/remote/remoteConfigs.types'
import { FormBuyerFields } from 'src/features/checkout/typings/checkoutFormTypings'

import { ErrorFullScreenModal } from '../components/ErrorFullScreenModal'

type TDigitalCheckoutProviderProps = {
  children: React.ReactNode
  productSellGroupId: number
  subscriptionPeriods: SubscriptionPeriodConfig[] | null
  featureFlags: CheckoutFeatureFlags | null
}

export type TDigitalCheckoutContext = {
  configCheckout: Checkout['config'] | null
  modulesCheckout: Checkout['modules'] | null
  getSubscriptionPeriodInfo: (
    subscriptionPeriod: number
  ) => SubscriptionPeriodConfig | null
  getFeatureFlag: (
    context: keyof CheckoutFeatureFlags,
    flagName: string
  ) => boolean
  form: {
    addDisabledField: (field: FormBuyerFields) => void
    removeDisabledField: (field: FormBuyerFields) => void
    isDisabledField: (field: FormBuyerFields) => boolean
  }
}

export const DigitalCheckoutContext = createContext(
  {} as TDigitalCheckoutContext
)

export function DigitalCheckoutProvider({
  children,
  productSellGroupId,
  subscriptionPeriods,
  featureFlags,
}: TDigitalCheckoutProviderProps) {
  const { config, modules } = checkoutFactory(productSellGroupId)
  const [fieldsDisabled, setFieldDisabled] = useState<FormBuyerFields[]>([])

  const addDisabledField = useCallback(
    (field: FormBuyerFields) => {
      if (fieldsDisabled.includes(field)) return

      setFieldDisabled(currentState => [...currentState, field])
    },
    [fieldsDisabled]
  )

  const removeDisabledField = useCallback((field: FormBuyerFields) => {
    setFieldDisabled(currentState =>
      currentState.filter(item => item !== field)
    )
  }, [])

  const isDisabledField = useCallback(
    (field: FormBuyerFields) => {
      return fieldsDisabled.includes(field)
    },
    [fieldsDisabled]
  )

  const getSubscriptionPeriodInfo = useCallback(
    (subscriptionPeriod: number) => {
      if (!subscriptionPeriods) return null

      return (
        subscriptionPeriods.find(({ days }) => subscriptionPeriod === days) ||
        null
      )
    },
    [subscriptionPeriods]
  )

  const getFeatureFlag = useCallback(
    (context: keyof CheckoutFeatureFlags, flagName: string) => {
      return featureFlags?.[context][flagName] || false
    },
    [featureFlags]
  )

  return (
    <DigitalCheckoutContext.Provider
      value={{
        configCheckout: config,
        modulesCheckout: modules,
        getSubscriptionPeriodInfo,
        getFeatureFlag,
        form: {
          addDisabledField,
          removeDisabledField,
          isDisabledField,
        },
      }}
    >
      <>
        <ErrorFullScreenModal />
        {children}
      </>
    </DigitalCheckoutContext.Provider>
  )
}
