import styled from '@emotion/styled'
import { css, Text } from '@queimadiaria/ui'

import { ColorValueHex } from 'src/features/product/typings/colorTypings'
import { CountdownItem } from 'src/hooks/useCountdown/useCountdown'

export interface CountdownProps {
  countdown: CountdownItem
  fgColor: ColorValueHex
  bgColor: ColorValueHex
  showHours?: boolean
  separatorColor?: ColorValueHex
  timeScaleInfoColor?: ColorValueHex
  separator?: boolean
}

export const ClassicTimer = ({
  countdown,
  fgColor,
  bgColor,
  showHours = true,
  timeScaleInfoColor = bgColor,
  separatorColor = bgColor,
  separator = false,
}: CountdownProps) => {
  const renderDigit = (label: string, value: string) => (
    <DigitContainer
      digitLabel={label}
      bg={bgColor}
      digitLabelColor={timeScaleInfoColor}
    >
      <Digit style={{ color: fgColor }}>{value}</Digit>
    </DigitContainer>
  )
  return (
    <CountdownContainer separator={separator}>
      {showHours && renderDigit('HORA', countdown.hours)}
      {showHours && separator && (
        <DigitSeparator style={{ color: separatorColor }}>:</DigitSeparator>
      )}
      {renderDigit('MIN', countdown.minutes)}
      {separator && (
        <DigitSeparator style={{ color: separatorColor }}>:</DigitSeparator>
      )}
      {renderDigit('SEG', countdown.seconds)}
    </CountdownContainer>
  )
}

const DigitContainer = styled.div<{
  digitLabel: string
  bg: ColorValueHex
  digitLabelColor: ColorValueHex
}>(({ digitLabel, bg, digitLabelColor }) =>
  css({
    position: 'relative',
    width: ['32px', '32px', '41px'],
    height: ['32px', '32px', '41px'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: bg,
    borderRadius: '4px',
    ':last-child': {
      marginRight: '0px',
    },
    ':after': {
      content: `"${digitLabel}"`,
      color: digitLabelColor,
      left: 0,
      zIndex: 1,
      width: '100%',
      height: '10px',
      display: 'block',
      fontSize: '10px',
      fontWeight: 'bold',
      lineHeight: '10px',
      position: 'absolute',
      textAlign: 'center',
      top: 'calc(100% + 3px)',
    },
  })
)

const Digit = styled(Text)`
  font-family: 'Graphik';
  font-weight: 700;
  font-size: 25.71px;
  line-height: 28.29px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 22px;
  }
`

const CountdownContainer = styled.div<{ separator: boolean }>(({ separator }) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    gap: separator ? '2px' : '8px',
    height: '50px',
  })
)
const DigitSeparator = styled.div`
  display: flex;
  font-family: 'Graphik';
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`
