import { useEffect, useState } from 'react'

export function useSizeScreen() {
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    const updateSize = () => setScreenWidth(window.innerWidth)

    updateSize()
    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const isSmaller = (size: number) => screenWidth < size
  const isBigger = (size: number) => screenWidth > size

  if (screenWidth === 0)
    return { isSmaller: () => false, isBigger: () => false }

  return { isSmaller, isBigger }
}
