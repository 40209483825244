import styled from '@emotion/styled'
import { InputHTMLAttributes } from 'react'

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  value?: string
  placeholder?: string
  icon?: JSX.Element
  onValueChange: (value: string) => void
  hasErrors?: boolean
}

export const TextInput = ({
  name,
  value,
  placeholder,
  onValueChange,
  icon,
  hasErrors = false,
  ...props
}: TextInputProps) => {
  return (
    <InputContainer className={hasErrors ? 'error' : ''}>
      {icon && <InputIcon>{icon}</InputIcon>}
      <input
        id={name}
        name={name}
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={e => onValueChange(e.target.value)}
        {...props}
      />
      {hasErrors && (
        <ErrorIcon>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.99967 11.3334C8.18856 11.3334 8.3469 11.2695 8.47468 11.1417C8.60245 11.0139 8.66634 10.8556 8.66634 10.6667C8.66634 10.4778 8.60245 10.3195 8.47468 10.1917C8.3469 10.0639 8.18856 10 7.99967 10C7.81079 10 7.65245 10.0639 7.52467 10.1917C7.3969 10.3195 7.33301 10.4778 7.33301 10.6667C7.33301 10.8556 7.3969 11.0139 7.52467 11.1417C7.65245 11.2695 7.81079 11.3334 7.99967 11.3334ZM7.99967 8.66671C8.18856 8.66671 8.3469 8.60282 8.47468 8.47504C8.60245 8.34726 8.66634 8.18893 8.66634 8.00004V5.33337C8.66634 5.14449 8.60245 4.98615 8.47468 4.85837C8.3469 4.7306 8.18856 4.66671 7.99967 4.66671C7.81079 4.66671 7.65245 4.7306 7.52467 4.85837C7.3969 4.98615 7.33301 5.14449 7.33301 5.33337V8.00004C7.33301 8.18893 7.3969 8.34726 7.52467 8.47504C7.65245 8.60282 7.81079 8.66671 7.99967 8.66671ZM7.99967 14.6667C7.07745 14.6667 6.21079 14.4917 5.39967 14.1417C4.58856 13.7917 3.88301 13.3167 3.28301 12.7167C2.68301 12.1167 2.20801 11.4112 1.85801 10.6C1.50801 9.78893 1.33301 8.92226 1.33301 8.00004C1.33301 7.07782 1.50801 6.21115 1.85801 5.40004C2.20801 4.58893 2.68301 3.88337 3.28301 3.28337C3.88301 2.68337 4.58856 2.20837 5.39967 1.85837C6.21079 1.50837 7.07745 1.33337 7.99967 1.33337C8.9219 1.33337 9.78856 1.50837 10.5997 1.85837C11.4108 2.20837 12.1163 2.68337 12.7163 3.28337C13.3163 3.88337 13.7913 4.58893 14.1413 5.40004C14.4913 6.21115 14.6663 7.07782 14.6663 8.00004C14.6663 8.92226 14.4913 9.78893 14.1413 10.6C13.7913 11.4112 13.3163 12.1167 12.7163 12.7167C12.1163 13.3167 11.4108 13.7917 10.5997 14.1417C9.78856 14.4917 8.9219 14.6667 7.99967 14.6667ZM7.99967 13.3334C9.48856 13.3334 10.7497 12.8167 11.783 11.7834C12.8163 10.75 13.333 9.48893 13.333 8.00004C13.333 6.51115 12.8163 5.25004 11.783 4.21671C10.7497 3.18337 9.48856 2.66671 7.99967 2.66671C6.51079 2.66671 5.24967 3.18337 4.21634 4.21671C3.18301 5.25004 2.66634 6.51115 2.66634 8.00004C2.66634 9.48893 3.18301 10.75 4.21634 11.7834C5.24967 12.8167 6.51079 13.3334 7.99967 13.3334Z'
              fill='#252525'
            />
          </svg>
        </ErrorIcon>
      )}
    </InputContainer>
  )
}

//TODO: parametrizar baseado em style components "themes"
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  border: 1px solid #bdbdbd;
  border-radius: 8px;
  transition: border 0.4s ease, box-shadow 0.4s ease;

  &.error {
    border: 1px solid #cc0000;
    background-color: #fef2f3;
  }
  &.error-icon {
    padding: 8px;
  }

  &:focus-within {
    border-color: #ffb56d;
    box-shadow: 0px 0px 0px 4px rgba(255, 79, 0, 0.24);
  }

  & input {
    flex: 1;
    padding: 8px 12px;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    max-height: 48px;
    outline: none;
    background-color: transparent;
  }
`

const InputIcon = styled.span`
  padding-left: 8px;
`
const ErrorIcon = styled.span`
  padding-right: 8px;
`
