import styled from '@emotion/styled'

export interface CouponLoaderProps {
  color: string
}
export const CouponLoader = ({ color }: CouponLoaderProps) => (
  <LoaderContainer>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      width={32}
      height={32}
      style={{
        shapeRendering: 'auto',
        display: 'block',
        background: '0 0',
      }}
    >
      <rect fill={color} height={16} width={16} ry={8} rx={8} x={42}>
        <animate
          repeatCount='indefinite'
          begin='-0.9230769230769231s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(27.692 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.8461538461538461s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(55.385 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.7692307692307693s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(83.077 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.6923076923076923s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(110.77 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.6153846153846154s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(138.462 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.5384615384615384s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(166.154 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.46153846153846156s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(193.846 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.38461538461538464s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(221.538 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.3076923076923077s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(249.23 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.23076923076923078s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(276.923 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.15384615384615385s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(304.615 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='-0.07692307692307693s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <rect
        fill={color}
        height={16}
        width={16}
        ry={8}
        rx={8}
        x={42}
        transform='rotate(332.308 50 50)'
      >
        <animate
          repeatCount='indefinite'
          begin='0s'
          dur='1s'
          keyTimes='0;1'
          values='1;0'
          attributeName='opacity'
        />
      </rect>
      <g />
    </svg>
  </LoaderContainer>
)

const LoaderContainer = styled.div`
  padding: 24px;
`
