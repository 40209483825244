import LogoNubankSvg from '../../assets/nu_bank_logo.svg'
import styles from './styles.module.css'

type HeadNupayProps = {
  title: string
}

export function HeadNupay({ title }: HeadNupayProps) {
  return (
    <div className={styles.container}>
      <LogoNubankSvg />
      <h2>{title}</h2>
    </div>
  )
}
