import { addMilliseconds, isAfter } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { useInterval } from 'react-use'

import { useCountdown } from 'src/hooks/useCountdown/useCountdown'

import { PendingAuthorizationProps } from '.'

export const AUTHORIZATION_POLLING_TIME_LIMIT = 10 * 60 * 1000 // 15 minutes (em milissegundos)
export const AUTHORIZATION_POLLING_INTERVAL = 5 * 1000 // 5 seconds (em milissegundos)

export function usePendingAuthorizationComponent({
  handleAuthorizationPolling,
  onError,
}: PendingAuthorizationProps) {
  const expiresAt = useMemo(
    () => addMilliseconds(new Date(), AUTHORIZATION_POLLING_TIME_LIMIT),
    []
  )
  const countdown = useCountdown(expiresAt, true)

  useInterval(handleAuthorizationPolling, AUTHORIZATION_POLLING_INTERVAL)

  useEffect(() => {
    if (isAfter(new Date(), expiresAt)) onError('TimeoutError')
  }, [countdown])

  return {
    countdown,
  }
}
