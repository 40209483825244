/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = {
  track: () => {},
  identify: () => {},
  register: () => {},
  get_distinct_id: () => '',
}

const mixpanelWithFallbackToNoop: any = () => {
  const mixpanel: any = (window as any).mixpanel || noop
  return mixpanel
}

export const mixpanel: any = mixpanelWithFallbackToNoop
