const addLeadingZero = (value: number): string => {
  const isLessThan10 = value < 10
  if (isLessThan10) return `0${value}`
  const valueAsStr = value.toString()
  return valueAsStr
}

/**
 * Converts a number of days into hours.
 *
 * @param days - The number of days to convert into hours.
 * @returns The equivalent number of hours.
 */
export const getHoursFromDays = (days: number) => {
  return (days ?? 0) * 24
}

/**
 * Formats timer values into a displayable format.
 *
 * @param days - Optional. The number of days in the timer.
 * @param hours - Optional. The number of hours in the timer.
 * @param minutes - Optional. The number of minutes in the timer.
 * @param seconds - Optional. The number of seconds in the timer.
 * @returns An object containing formatted hours, minutes, and seconds.
 */
export const getDisplayTimer = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}) => {
  if (
    (hours && hours < 0) ||
    (minutes && (minutes < 0 || minutes > 60)) ||
    (seconds && (seconds < 0 || seconds > 60))
  )
    throw new Error(
      'Unidade de medida incorreta de tempo (horas, minutos, segundos). Certifique-se de utilizar valores >=0 e < 60'
    )
  const calculatedHours = days
    ? addLeadingZero(getHoursFromDays(days) + (hours ?? 0))
    : addLeadingZero(hours ?? 0)
  const parsedMinutes = addLeadingZero(minutes ?? 0)
  const parsedSeconds = addLeadingZero(seconds ?? 0)

  return {
    hours: calculatedHours,
    minutes: parsedMinutes,
    seconds: parsedSeconds,
  }
}
