import { PendingIcon } from './PendingIcon'
import styles from './styles.module.css'
import { usePendingAuthorizationComponent } from './usePendingAuthorizationComponent'

export interface PendingAuthorizationProps {
  handleAuthorizationPolling: () => Promise<void>
  onError: (type: 'TimeoutError') => void
}

export const PendingAuthorization = (props: PendingAuthorizationProps) => {
  const { countdown } = usePendingAuthorizationComponent(props)

  return (
    <div className={styles.generalContainer}>
      <div className={styles.almostThere}>
        <h2>Quase lá...</h2>
        <p>
          Autorize dentro de{' '}
          <span
            className={styles.timer}
          >{`${countdown.minutes}:${countdown.seconds}`}</span>{' '}
          <br />
          para garantir sua compra.
        </p>
      </div>
      <div className={styles.paymentInstructions}>
        <PendingIcon />
        <p className={styles.beforePay}>Antes de pagar sua compra</p>
        <h2 className={styles.openNubank}>
          Abra o app do Nubank no seu celular para autorizar
        </h2>
      </div>
      <div className={styles.loaderContainer}>
        Aguardando aprovação <div className={styles.loader}></div>
      </div>
    </div>
  )
}
