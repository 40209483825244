import { ReactNode } from 'react'

import { PaymentMethods } from 'src/modules/payment/methods/paymentMethodsModule.types'

import { SingleStepTemplateComponent } from './singleStep/singleStep.types'

export enum AvailableTemplates {
  SingleStep = 'single-step',
  // MultiStep = 'multi-step',
}

export interface TemplateProps {
  heading: string
  purchaseValue: number
  purchaseButtonText: string
  disclaimer: string
  hidePaymentMethods: boolean
  personalDataComponent: ReactNode
  currentPaymentMethod: PaymentMethods
  paymentMethods: {
    paymentMethod: PaymentMethods
    component: ReactNode
  }[]
  orderbumpComponent: ReactNode
  recaptchaComponent: ReactNode
  onSelectPaymentMethod: (paymentMethod: PaymentMethods) => void
  onSubmit: () => void
}

export type TemplateComponent = SingleStepTemplateComponent
// | MultiStepTemplateComponent

export function templateIsOfType<T extends TemplateComponent>(
  template: TemplateComponent,
  functionName: string
): template is T {
  return template && template.name === functionName
}
