import { addMilliseconds, intervalToDuration, isBefore } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import { getDisplayTimer } from 'src/utils/timer'

export type CountdownItem = {
  hours: string
  minutes: string
  seconds: string
}

const emptyCountdown: CountdownItem = {
  hours: '00',
  minutes: '00',
  seconds: '00',
}

export function useCountdown(
  expiresAt: Date,
  hasStarted: boolean,
  compensateMilliseconds = 0
) {
  const [countdown, setCountdown] = useState<CountdownItem>(emptyCountdown)

  const updateCountdown = useCallback(() => {
    const now = addMilliseconds(new Date(), compensateMilliseconds)
    const isExpired = isBefore(expiresAt, now)

    if (isExpired) return setCountdown(emptyCountdown)

    const duration = intervalToDuration({
      start: now,
      end: expiresAt,
    })
    const { hours, minutes, seconds } = getDisplayTimer({
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
    })
    const durationCountdown: CountdownItem = {
      hours: hours ?? '00',
      minutes: minutes ?? '00',
      seconds: seconds ?? '00',
    }

    return setCountdown(durationCountdown)
  }, [expiresAt])

  useEffect(updateCountdown, []) // First render update

  useInterval(updateCountdown, hasStarted ? 1000 : null)

  return countdown
}
