import { CountdownModule, ModuleCountdownReturn } from './countdownModule.types'
import { DefaultTemplateComponent } from './templates/DefaultTemplate'
import { PhraseTemplateComponent } from './templates/PhraseTemplate'

export const configurableTemplates = {
  DefaultTemplateComponent,
  PhraseTemplateComponent,
}

export const countdownModule: CountdownModule = ({ template }) => {
  const Component = configurableTemplates[template]

  return {
    template,
    component: Component,
  } as ModuleCountdownReturn
}
