import { atom } from 'jotai'

import { Coupon } from 'src/modules/coupon/types/coupon.types'

const cupomDigitalCheckoutAtom = atom<Coupon | null>(null)
const focusedDigitalCheckoutAtom = atom<boolean>(false)

export const cupomDigitalCheckoutAtoms = {
  cupomDigitalCheckoutAtom,
  focusedDigitalCheckoutAtom,
}
