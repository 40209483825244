import { useAtomValue } from 'jotai'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { checkoutAtoms } from 'src/features/checkout/atoms/checkoutAtoms'

import { mixpanel } from '../utils/mixpanel'

export function useTryToCaptureDataToMymetric() {
  const router = useRouter()
  const clientGeolocation = useAtomValue(checkoutAtoms.clientGeolocation)

  const handleTryToCapturaDataToMymetric = useCallback(async () => {
    const trackerCookie = Cookies.get('mm_tracker')
    const mmTracker = !!trackerCookie ? JSON.parse(trackerCookie) : null

    return {
      fpp: Cookies.get('_fbp'),
      fpc: Cookies.get('_fbc'),
      mmTracker,
      pageLocation: `${
        typeof window !== 'undefined' && window.location.origin
      }${router.asPath}`,
      distinctId:
        mixpanel() && mixpanel().get_distinct_id
          ? mixpanel().get_distinct_id()
          : null,
      ip: clientGeolocation?.ip ?? '',
      gaSessionId: Cookies.get('_ga_V3XG8WT6ZD'),
    }
  }, [clientGeolocation])

  return { handleTryToCapturaDataToMymetric }
}
