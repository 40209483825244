import { useCallback, useEffect, useState } from 'react'

import { getOnlyNumbers, validatorCPF, validatorEmail } from 'src/utils/string'

import { nupayGateway } from '../gateway'
import { AuthorizationStatus } from '../gateway/types/gateway.types'
import { NupayComponentProps } from '../NupayMethod.types'
import { PaymentChosenFormData, PaymentOptions } from './PaymentChosen/types'

export function useNupayComponent({
  userData,
  onError,
  onChange,
  offer,
}: NupayComponentProps) {
  const [authReqId, setAuthReqId] = useState<string | null>(null)
  const [isSendedAuthorization, setIsSendedAuthorization] = useState(false)
  const [isPendingAuthorization, setIsPendingAuthorization] = useState(false)
  const [isFallback, setIsFallback] = useState(false)
  const [paymentChosenValues, setPaymentChosenValues] =
    useState<null | PaymentChosenFormData>(null)
  const [paymentOptions, setPaymentOptions] = useState<
    PaymentOptions | undefined
  >(undefined)

  const getPaymentConditions = useCallback(async () => {
    try {
      if (!authReqId) return

      const paymentConditions = await nupayGateway.getPaymentConditions({
        authReqId,
        offerId: offer.id,
      })

      if (!paymentConditions.paymentConditions) {
        throw new Error('Payment Conditions null')
      }

      const optionsAvailable = paymentConditions.paymentConditions.reduce(
        (acc, item) => {
          if (item.type === 'credit') {
            return {
              ...acc,
              creditPayment: {
                isAvailable: true,
                installments: item.installmentPlans.map(
                  ({ amount, quantity }) => ({
                    installment: quantity,
                    price: amount,
                  })
                ),
              },
            } as PaymentOptions
          }
          if (item.type === 'debit') {
            return {
              ...acc,
              debitPayment: {
                isAvailable: true,
              },
            } as PaymentOptions
          }

          return acc
        },
        {
          creditPayment: {
            isAvailable: false,
          },
          debitPayment: {
            isAvailable: false,
          },
        } as PaymentOptions
      )

      setIsFallback(paymentConditions.isFallback)
      setPaymentOptions(optionsAvailable)
    } catch (err) {
      console.error('Error on request payment conditions nupay', err)
      onError('InsufficientFundsError')
    }
  }, [authReqId, offer.id])

  const handleRequestAuthorization = useCallback(async () => {
    try {
      const { document, email } = userData
      const isValidDocument = validatorCPF(document || '')
      const isValidEmail = validatorEmail(email || '')

      if (!isValidDocument || !isValidEmail) {
        onError('ValidationError')
        return
      }

      setIsSendedAuthorization(true)
      const { authReqId } = await nupayGateway.requestAuthorization({
        email,
        document: getOnlyNumbers(document),
      })
      setAuthReqId(authReqId)
      setIsPendingAuthorization(true)
    } catch (err) {
      console.error('Error on request authorization nupay', err)
      onError('HttpError')
    }
  }, [userData])

  const handleAuthorizationPolling = useCallback(async () => {
    if (authReqId) {
      try {
        const response = await nupayGateway.checkAuthorizationStatus({
          authReqId,
        })
        const { status, message } = response
        if (status === 'SUCCESS') {
          await getPaymentConditions()
          setIsPendingAuthorization(false)
          return
        }
        authorizationPollingBusinessErrorHandler(status, message)
      } catch (err) {
        console.error('Error on check authorization polling nupay', err)
        onError('HttpError')
      }
    }
  }, [authReqId])

  const authorizationPollingBusinessErrorHandler = (
    status: AuthorizationStatus,
    message: string
  ) => {
    if (status === 'EXPIRED') onError('TimeoutError')
    if (status === 'UNAUTHORIZED') onError('UnauthorizedError')
    if (status === 'ERROR') onError('HttpError')
    console.error(
      'Business Error on check authorization polling nupay',
      message
    )
  }

  const handleChangePaymentChosen = useCallback(
    (paymentValue: PaymentChosenFormData) => {
      setPaymentChosenValues(paymentValue)
    },
    []
  )

  useEffect(() => {
    onChange({
      authReqId,
      isFallback,
      installment: paymentChosenValues?.installment || null,
      fundingSource: paymentChosenValues?.method || null,
      isSendedAuthorization,
    })
  }, [isSendedAuthorization, authReqId, paymentChosenValues, isFallback])

  return {
    authReqId,
    isPendingAuthorization,
    handleRequestAuthorization,
    handleAuthorizationPolling,
    handleChangePaymentChosen,
    paymentOptions,
    isSendedAuthorization,
    isFallback,
  }
}
