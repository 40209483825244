import { useAtom } from 'jotai'
import { useEffect } from 'react'

import { useCheckoutParamsFromUrl } from 'src/features/layout/hooks/useCheckoutParamsFromUrl'

import { orderBumpAtoms } from '../atoms/orderBumpAtoms'

export function useOrderBumpCheckoutStore() {
  const { selectedOrderBump } = orderBumpAtoms
  const { markBump } = useCheckoutParamsFromUrl()

  const [isSelectedOrderBump, setIsSelectedOrderBump] =
    useAtom(selectedOrderBump)

  useEffect(() => setIsSelectedOrderBump(markBump), [])

  return {
    isSelectedOrderBump,
    setIsSelectedOrderBump,
  }
}
