import { PixMethodConfig } from './PixMethod.types'
import { pagarmePix } from './providers/adyen'

export const pixMethod = ({ provider }: PixMethodConfig) => {
  const pixModulesByProvider = {
    adyen: pagarmePix,
  }

  return {
    component: pixModulesByProvider[provider],
  }
}
