import { useContext } from 'react'

import { DigitalCheckoutContext } from '../Providers/DigitalCheckoutProvider'

export function useDigitalCheckoutContext() {
  const context = useContext(DigitalCheckoutContext)
  if (!context)
    throw Error('Do not use this hook without a DigitalCheckout provider')

  return context
}
