import styled from '@emotion/styled'
import { Box, Text } from '@queimadiaria/ui'
import { addMilliseconds, isAfter, isBefore } from 'date-fns'
import { useRef } from 'react'

import { ColorValueHex } from 'src/features/product/typings/colorTypings'
import { useCountdown } from 'src/hooks/useCountdown/useCountdown'

import { ClassicTimer } from '../../components/ClassicTimer'
import { CountdownTemplate } from '../../countdownModule.types'

export type CountdownBackgroundProp = [startColor: string, endColor: string]

export interface PhraseTemplateProps {
  startAt: Date
  endsAt: Date
  phrase: string
  fgColor?: ColorValueHex
  bgColor?: ColorValueHex
  compensateMilliseconds?: number
}
type PhraseTemplate = CountdownTemplate<React.ReactElement, PhraseTemplateProps>
export const PhraseTemplateComponent: PhraseTemplate = ({
  startAt,
  endsAt,
  fgColor = '#000',
  bgColor = '#F5F5F5',
  phrase,
  compensateMilliseconds = 0,
}) => {
  const now = addMilliseconds(new Date(), compensateMilliseconds)
  const hasStarted = isBefore(startAt, now)
  const hasExpired = isAfter(now, endsAt)

  const countdown = useCountdown(endsAt, hasStarted, compensateMilliseconds)

  const ref = useRef<HTMLDivElement>(null)

  if (!hasStarted || hasExpired) return <></>

  return (
    <Root ref={ref}>
      <Container data-testid='countdown' style={{ backgroundColor: bgColor }}>
        <ContentContainer>
          <Phrase style={{ color: fgColor }}>{phrase}</Phrase>
          <OfferDisclaimer>Essa oferta expira em:</OfferDisclaimer>
        </ContentContainer>
        <ClassicTimer
          fgColor='#FFF'
          bgColor='#000'
          countdown={countdown}
          separator={true}
          showHours={false}
        />
      </Container>
    </Root>
  )
}

const Root = styled(Box)`
  overflow: 'scroll';
  position: relative;
`

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 20px;
  gap: 25px;

  @media only screen and (max-width: 720px) {
    padding-block: 12px;
  }
`

const ContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`
const Phrase = styled(Text)`
  font-family: 'Metropolis';
  color: black;
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -2px;
  letter-spacing: -4%;

  @media only screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 28px;
  }
`

const OfferDisclaimer = styled(Text)`
  font-family: 'Metropolis';
  color: #ff4f00;
  font-weight: 900;
  font-size: 18.88px;
  line-height: 18.88px;
  letter-spacing: -4%;

  @media only screen and (max-width: 768px) {
    font-size: 15.22px;
    line-height: 15.22px;
  }
`
