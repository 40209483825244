import * as RadioButtonRadix from '@radix-ui/react-radio-group'
import { Control, Controller } from 'react-hook-form'

import { Badge } from '../../../Badge'
import { PaymentChosenFormData } from '../../types'
import styles from './styles.module.css'

export type RadioButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<PaymentChosenFormData, any>
  methodWatch?: PaymentChosenFormData['method']
  creditTagDescription?: string
  disabledDebit: boolean
  disabledCredit: boolean
}

export function MethodPaymentRadioGroup({
  control,
  methodWatch,
  creditTagDescription,
  disabledCredit,
  disabledDebit,
}: RadioButtonProps) {
  return (
    <div className={styles.container}>
      <Controller
        name='method'
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, ...restFiel } }) => (
          <RadioButtonRadix.Root
            className={styles.radioGroupRoot}
            aria-label='method nupay'
            {...restFiel}
            onValueChange={onChange}
          >
            <div
              className={styles.radioGroupOptionContainer}
              style={{
                opacity: disabledCredit ? 0.3 : 1,
              }}
            >
              <RadioButtonRadix.Item
                value='credit'
                className={`${styles.radioGroupItem} ${
                  !disabledCredit ? styles.radioGroupEnabled : ''
                }`}
                disabled={disabledCredit}
                style={
                  methodWatch === 'credit'
                    ? { border: '2px solid #820AD1' }
                    : undefined
                }
                id='credit'
              >
                <RadioButtonRadix.Indicator
                  className={styles.radioGroupIndicator}
                />
              </RadioButtonRadix.Item>
              <label className={styles.radioGroupLabel} htmlFor='credit'>
                Crédito
                {disabledCredit && (
                  <span>Limite insuficiente para esta compra</span>
                )}
              </label>
              {!disabledCredit && (
                <div>
                  {creditTagDescription && (
                    <Badge text={creditTagDescription} color='#820AD1' />
                  )}
                </div>
              )}
            </div>
            <div
              className={styles.radioGroupOptionContainer}
              style={{
                opacity: disabledDebit ? 0.3 : 1,
              }}
            >
              <RadioButtonRadix.Item
                value='debit'
                className={`${styles.radioGroupItem} ${
                  !disabledDebit ? styles.radioGroupEnabled : ''
                }`}
                disabled={disabledDebit}
                style={
                  methodWatch === 'debit'
                    ? { border: '2px solid #820AD1' }
                    : undefined
                }
                id='debit'
              >
                <RadioButtonRadix.Indicator
                  className={styles.radioGroupIndicator}
                />
              </RadioButtonRadix.Item>
              <label className={styles.radioGroupLabel} htmlFor='debit'>
                Débito
                {disabledDebit && (
                  <span>Saldo insuficiente para esta compra</span>
                )}
              </label>
            </div>
          </RadioButtonRadix.Root>
        )}
      />
    </div>
  )
}
