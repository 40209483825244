/**
 * Formats a numerical value into a currency string using the Brazilian Portuguese locale.
 *
 * @param value - The numerical value to format.
 * @returns The formatted currency string.
 */
export function formatCurrency(value: number) {
  const format = value?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
  return format
}
