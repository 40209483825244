import { atom, useAtom } from 'jotai'
import { useMemo } from 'react'

import { useDigitalCheckoutContext } from 'src/screens/DigitalCheckout/hooks/useDigitalCheckoutContext'

interface ErrorDigitalCheckout {
  title: string
  text: string
}

export const errorDigitalCheckoutAtom = atom<ErrorDigitalCheckout>(
  {} as ErrorDigitalCheckout
)

export function useErrorFullScreenModal() {
  //ensure provider
  useDigitalCheckoutContext()

  const [errorDigitalCheckout, setErrorDigitalCheckout] = useAtom(
    errorDigitalCheckoutAtom
  )

  const showErrorFullScreenModal = (title: string, text: string) => {
    setErrorDigitalCheckout({
      title,
      text,
    })
  }
  const hideErrorFullScreenModal = () => {
    setErrorDigitalCheckout({} as ErrorDigitalCheckout)
  }

  const isErrordigitalCheckoutOpen = useMemo(
    () => Object.keys(errorDigitalCheckout).length > 0,
    [errorDigitalCheckout]
  )

  return {
    showErrorFullScreenModal,
    hideErrorFullScreenModal,
    isErrordigitalCheckoutOpen,
    errorDigitalCheckout,
  }
}
